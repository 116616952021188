import React from "react"

import Layoutxl from "../components/layout/Layout-xl"
import Maximoworld from "../components/maximoworld"

const maximoworld = () => {
  return (
    <Layoutxl>
      <Maximoworld />
    </Layoutxl>
  )
}

export default maximoworld
