import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

function MaximoworldDesc({ _MaximoworldDesc }) {
  let MaximoworldDesc = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/(maximoworld-desc)/" } }
        ) {
          nodes {
            exports {
              MaximoworldDesc {
                description1
                description2
                srcIMG1
              }
            }
          }
        }
      }
    `)
    MaximoworldDesc = result.allMdx.nodes[0].exports.MaximoworldDesc
      ? result.allMdx.nodes[0].exports.MaximoworldDesc
      : result.allMdx.nodes[1].exports.MaximoworldDesc
  } catch (error) {
    MaximoworldDesc = _MaximoworldDesc
  }
  return (
    <div
      className="MaximoworldDesc container mobilelayoutcontainer"
      data-testid="WhyMaximoS3"
    >
      {MaximoworldDesc?.map((item, index) => {
        return (
          <div key={index}>
            <div className="row paddingClass">
              <div className="col-12 col-md-5  d-flex justify-content-center align-items-center">
                <img
                  src={item.srcIMG1}
                  alt={`IBM Maximo: ${item.title1}`}
                  title={`IBM Maximo: ${item.title1}`}
                />
              </div>
              <div className="col-12 col-md-7 pt-5">
                <p dangerouslySetInnerHTML={{ __html: item.description1 }}></p>
              </div>
            </div>
            <div className="row ">
              <div className="col-12 order-1 order-md-2 col-md-12 pt-3">
                <div className="mx-md-5 px-md-5 pb-5">
                  <p
                    className="mx-5 bold text-center"
                    dangerouslySetInnerHTML={{ __html: item.description2 }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MaximoworldDesc
