import React from "react"
import { Helmet } from "react-helmet"

import TitleSection from "./maximoworld-title"
import MaximoworldDesc from "./maximoworld-desc/section1"
import MaximoworldSec2 from "./maximoworld-desc/section2"
import AboutSection3 from "../about/section-about3"
import ContactForm from "../forms/contact-form"

import "./index.scss"
const Maximoworld = () => {
  return (
    <div className="Maximoworld" data-testid="Maximoworld">
      <Helmet>
        <title>Maximo world 2022 | MAXapps Maximo Mobile</title>
        <meta name="robots" content="index, follow" />.
        <meta name="rights" content="© Copyright 2022 MAXapps Maximo Mobile" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.maxapps-maximo.com/maximo-world"
        />
        <meta property="og:site_name" content="maxapps-maximo" />
        <meta property="og:locale" content="en-US" />
        <meta
          id="og-title"
          property="og:title"
          content={"Maximo world 2022 | MAXapps Maximo Mobile"}
        />
        <meta
          id="meta-description"
          name="description"
          content="MaximoWorld is one of the world's most generous knowledge and experience sharing forums, featuring some of the world's best-run organizations combined with cutting-edge technology from IBM and other technology leaders.At MaximoWorld , passionate IBM Maximo users have been gathering with their community For over 20 years ."
        />
        <meta
          id="og-image"
          property="og:image"
          content="https://maxapps-maximo.com/og/maximoworld.png"
        />
        <meta
          property="twitter:image:src"
          content="https://maxapps-maximo.com/og/maximoworld.png"
        />
        <meta
          property="twitter:image"
          content="https://maxapps-maximo.com/og/maximoworld.png"
        />
        <link
          rel="canonical"
          key="https://www.maxapps-maximo.com/maximo-world"
          href="https://maxapps-maximo.com/maximo-world"
        />
      </Helmet>
      <TitleSection imgSrc={"/maxappsworld.png"} />
      <MaximoworldDesc />
      <div className="MaximoworldSec2background">
        <MaximoworldSec2 />
        <div class="container mobilelayoutcontainer text-center contain ">
          <img
            class="imageBottom"
            src="/contactus.svg"
            alt="Maximo Ibm: contact us"
            title="Maximo Ibm: contact us"
          />
        </div>
        <div class="container mobilelayoutcontainer ">
          <AboutSection3 btn={false} />
        </div>

        <div class="center container mobilelayoutcontainer pb-5">
          <ContactForm withDateTimePickerField={false} />
        </div>
      </div>
    </div>
  )
}

export default Maximoworld
